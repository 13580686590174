var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _vm.isDoctor
      ? _c("div", { staticClass: "content-center" }, [
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("van-field", {
                attrs: {
                  type: "text",
                  clearable: "",
                  label: "当前微信号真实姓名",
                  placeholder: "请输入当前微信号的真实姓名",
                },
                on: { input: _vm.onChangeText },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "center-button",
              class: _vm.btnEnable ? "enabled" : "disabled",
              on: { click: _vm.handleSubmit },
            },
            [_c("span", [_vm._v("提交")])]
          ),
        ])
      : _c("div", { staticClass: "tips" }, [
          _c("h2", [_vm._v("温馨提示")]),
          _vm._m(0),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [_vm._v("请先到瑞云医医生端完成医生审核信息")]),
      _c("p", [_vm._v("(该手机号未注册医生身份)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }